<template>
  <div id="SouthernOrder">
    <top></top>
    <!-- 主体表格 -->
    <div class="SouthernOrder_body_div">
      <!-- 导出数据 -->
      <div class="vue-json-excel" v-if="Terminal_type == 'pc'">
        <template>
          <download-excel
            class="export-excel-wrapper"
            :data="DetailsForm"
            :fields="json_fields"
            header="南航H5选座数据报表"
            name="南航H5选座数据报表.xls"
          >
            <el-button type="success">数据导出</el-button>
          </download-excel>
        </template>
      </div>

      <div class="SouthernOrder_body" :style="'height:' + table_height + 'px'">
        <table style="table-layout:fixed;" class="SouthernOrder_table" border="1">
          <tr>
            <td>姓名</td>
            <td>票号</td>
            <td>座位</td>
            <td v-if="Terminal_type == 'pc'">时间</td>
            <td v-if="Terminal_type == 'pc'">原始里程</td>
            <td v-if="Terminal_type == 'pc'">实际里程</td>
            <td v-if="Terminal_type == 'pc'">卡号</td>
            <td v-if="Terminal_type == 'pc'">卡密</td>
            <td v-if="Terminal_type == 'pc'">下单管理</td>
          </tr>
          <tr
            v-for="(item, index) in res"
            :key="index"
            @click="mobile(item, index)"
          >
          <!-- 姓名 -->
            <td>
              <span v-for="(it, ind) in item.xinxi" :key="ind"
                >{{ it.name }},</span
              >
            </td>
            <!-- 票号 -->
            <td>{{ item.xinxi[0].piao }}</td>
            <!-- 座位 -->
            <td>
              <span v-for="(it, ind) in item.xinxi" :key="ind"
                >{{ it.zuo }},</span
              >
            </td>
            <td v-if="Terminal_type == 'pc'">{{ item.time }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.pay_licheng }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.shiji }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.admin }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.pwd }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.personnel }}</td>
          </tr>
        </table>
      </div>

      <!-- mobile 独有-->
      <van-popup
        v-if="Terminal_type == 'mobile'"
        v-model="show"
        round
        position="bottom"
      >
        <div class="SouthernOrder_popup_div">
          <div class="SouthernOrder_popup_flex">
            <p>姓名：</p>
            <p>
              <span v-for="(it, id) in popup_item.xinxi" :key="id"
                >{{ it.name }},</span
              >
            </p>
          </div>
          <div class="SouthernOrder_popup_flex">
            <p>票号：</p>
            <p v-show="res[res_index]">
              {{ res[res_index].xinxi[0].piao }}
            </p>
          </div>
          <div class="SouthernOrder_popup_flex">
            <p>座位：</p>
            <p>
              <span v-for="(it, ind) in popup_item.xinxi" :key="ind"
                >{{ it.zuo }},</span
              >
            </p>
          </div>
          <div class="SouthernOrder_popup_flex">
            <p>时间：</p>
            <p>{{ popup_item.time }}</p>
          </div>
          <div class="SouthernOrder_popup_flex">
            <p>原始里程：</p>
            <p>{{ popup_item.pay_licheng }}</p>
          </div>
          <div class="SouthernOrder_popup_flex">
            <p>实际里程：</p>
            <p>{{ popup_item.shiji }}</p>
          </div>
          <div class="SouthernOrder_popup_flex">
            <p>南航卡号：</p>
            <p>{{ popup_item.admin }}</p>
          </div>
          <div class="SouthernOrder_popup_flex">
            <p>南航卡密：</p>
            <p>{{ popup_item.pwd }}</p>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
    
<script>
import top from "../components/BodyComponents.vue";
export default {
  name: "SouthernOrder",
  data() {
    return {
      table_height: sessionStorage.getItem("table_height"),
      res: [],
      json_fields: {
        姓名: "name",
        票号: "piao",
        座位: "zuo",
        时间: "time",
        原本支出里程: "pay_licheng",
        实际支出里程: "shiji",
        卡号: "admin",
        卡密: "pwd",
      },
      DetailsForm: [],
      show: false,
      popup_item: [],
      res_index: 0,
    };
  },
  components: {
    top,
  },
  created() {
    // 表格展示数据
    this.$https("selection/ren/chaxunh", "", "get").then((res) => {
      this.res = res.data.data;
    });
    // 南航选座H5报表
    if (this.Terminal_type == "pc") {
      this.$https("selection/ren/table", "", "get").then((res) => {
        this.DetailsForm = res.data.data;
      });
    }
  },
  mounted() {
    console.log("进入mounted");
  },
  methods: {
    mobile(item, index) {
      if (this.Terminal_type == "pc") return false;
      this.show = true;
      this.popup_item = item;
      this.res_index = index;
    },
  },
};
</script>
    
<style scoped>
body {
  background: #fafafa;
}
/* 穿透element-ui 消息提示 */
</style>